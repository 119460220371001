var StoreTypeSlug;
(function (StoreTypeSlug) {
    StoreTypeSlug["FastFood"] = "fast-food";
    StoreTypeSlug["FoodStore"] = "food-store";
})(StoreTypeSlug || (StoreTypeSlug = {}));
var StoreGroupSlug;
(function (StoreGroupSlug) {
    StoreGroupSlug["ALDI"] = "aldi";
    StoreGroupSlug["All"] = "all";
    StoreGroupSlug["Auchan"] = "auchan";
    StoreGroupSlug["AuchanSzupermarket"] = "auchan-szupermarket";
    StoreGroupSlug["BurgerKing"] = "burger-king";
    StoreGroupSlug["CBA"] = "cba";
    StoreGroupSlug["COOP"] = "coop";
    StoreGroupSlug["COOPSzuper"] = "coop-szuper";
    StoreGroupSlug["COOPSzuperPlusz"] = "coop-szuper-plusz";
    StoreGroupSlug["CitySPAR"] = "city-spar";
    StoreGroupSlug["EcoFamily"] = "eco-family";
    StoreGroupSlug["EcoFamilyCity"] = "eco-family-city";
    StoreGroupSlug["GRoby"] = "groby";
    StoreGroupSlug["Interspar"] = "interspar";
    StoreGroupSlug["KFC"] = "kfc";
    StoreGroupSlug["LIDL"] = "lidl";
    StoreGroupSlug["METRO"] = "metro";
    StoreGroupSlug["McDonalds"] = "mcdonalds";
    StoreGroupSlug["Penny"] = "penny";
    StoreGroupSlug["PlusMarket"] = "plus-market";
    StoreGroupSlug["PrivatElelmiszer"] = "privat";
    StoreGroupSlug["PrivatElelmiszerMax"] = "privat-max";
    StoreGroupSlug["Pr\u00EDma"] = "prima";
    StoreGroupSlug["Real"] = "real";
    StoreGroupSlug["RealPont"] = "real-pont";
    StoreGroupSlug["SparMarket"] = "spar-market";
    StoreGroupSlug["SparPartner"] = "spar-partner";
    StoreGroupSlug["SparSzupermarket"] = "spar-szupermarket";
    StoreGroupSlug["TescoExpress"] = "tesco-expressz";
    StoreGroupSlug["TescoExtra"] = "tesco-extra";
    StoreGroupSlug["TescoSzupermarket"] = "tesco-szupermarket";
})(StoreGroupSlug || (StoreGroupSlug = {}));
export { StoreGroupSlug, StoreTypeSlug, };
