var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
const BASE_URL = process.env.NODE_ENV === "production" ? "" : "http://localhost"; // 5.204.105.132 , https://87.229.84.84
const publicRequest = ({ url, data, method = "GET", }) => __awaiter(void 0, void 0, void 0, function* () {
    const rawResponse = yield window.fetch(`${BASE_URL}${url}`, Object.assign(Object.assign({ method }, (data && { body: JSON.stringify(data) })), (method === "POST" && { headers: { "Content-Type": "application/json" } })));
    const responseData = yield rawResponse.json();
    return {
        data: responseData,
        status: rawResponse.status,
    };
});
const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
});
export { axiosPrivate, publicRequest };
