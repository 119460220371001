import { useEffect, useState } from "react";
import { PASSWORD_REGEX } from "../constants/regex.contant";
const usePasswordForm = () => {
    const [matchFocus, setMatchFocus] = useState(false);
    const [matchPassword, setMatchPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [validMatch, setValidMatch] = useState(false);
    useEffect(() => {
        setValidPassword(PASSWORD_REGEX.test(password));
        setValidMatch(password === matchPassword);
    }, [password, matchPassword]);
    return {
        matchFocus,
        matchPassword,
        password,
        passwordFocus,
        setMatchFocus,
        setMatchPassword,
        setPassword,
        setPasswordFocus,
        validMatch,
        validPassword,
    };
};
export default usePasswordForm;
