import { useCallback, useContext, useDebugValue } from "react";
import AuthContext from "../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
const useAuth = () => {
    const { auth, convertAuthData, setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const logout = useCallback(() => {
        setAuth(null);
        navigate("/");
        //eslint-disable-next-line
    }, []);
    useDebugValue(auth, (auth) => ((auth === null || auth === void 0 ? void 0 : auth.username) ? "Logged In" : "Logged Out"));
    // return useContext(AuthContext);
    return {
        auth,
        convertAuthData,
        id: auth === null || auth === void 0 ? void 0 : auth.id,
        isLoggedIn: auth === null || auth === void 0 ? void 0 : auth.username,
        isOperator: auth === null || auth === void 0 ? void 0 : auth.isOperator,
        isStaff: auth === null || auth === void 0 ? void 0 : auth.isStaff,
        logout,
        setAuth,
    };
};
export default useAuth;
