import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CPagination, CPaginationItem } from "@coreui/react";
import { useMemo } from "react";
import "./Pagination.scss";
const arrayRange = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);
const createCurrentPageNumbers = (pageNumber, currentPageNumber) => {
    const maxNumPages = 5;
    if (pageNumber <= maxNumPages) {
        return arrayRange(1, pageNumber, 1);
    }
    else {
        let start = currentPageNumber - 2;
        let end = currentPageNumber + 3;
        if (start < 1) {
            start = 1;
        }
        if (end > pageNumber) {
            end = pageNumber;
        }
        return arrayRange(start, end, 1);
    }
};
const Pagination = ({ pageData, setPage }) => {
    const pageNumbers = useMemo(() => createCurrentPageNumbers((pageData === null || pageData === void 0 ? void 0 : pageData.num_pages) || 1, (pageData === null || pageData === void 0 ? void 0 : pageData.number) || 1), [pageData === null || pageData === void 0 ? void 0 : pageData.number, pageData === null || pageData === void 0 ? void 0 : pageData.num_pages]);
    if (!pageData || !(pageData.num_pages > 1)) {
        return null;
    }
    return (_jsxs(CPagination, Object.assign({ "aria-label": "Page navigation example" }, { children: [_jsx(CPaginationItem, Object.assign({ "aria-label": "Els\u0151", className: "cursor-pointer", disabled: !pageData.has_previous, onClick: () => setPage(1) }, { children: "Els\u0151" })), _jsx(CPaginationItem, Object.assign({ "aria-label": "El\u0151z\u0151", className: "cursor-pointer", disabled: !pageData.has_previous, onClick: () => setPage(pageData.previous_page_number) }, { children: _jsx("span", Object.assign({ "aria-hidden": "true" }, { children: "\u00AB" })) })), pageNumbers.map((pNumber) => {
                const paginationItemText = `page-${pNumber}`;
                return (_jsx(CPaginationItem, Object.assign({ "aria-label": paginationItemText, active: pNumber === pageData.number, className: "cursor-pointer", onClick: () => setPage(pNumber) }, { children: pNumber }), paginationItemText));
            }), _jsx(CPaginationItem, Object.assign({ "aria-label": "K\u00F6vetkez\u0151", className: "cursor-pointer", disabled: !pageData.has_next, onClick: () => setPage(pageData.next_page_number) }, { children: _jsx("span", Object.assign({ "aria-hidden": "true" }, { children: "\u00BB" })) })), _jsx(CPaginationItem, Object.assign({ "aria-label": "Utols\u00F3", className: "cursor-pointer", disabled: !pageData.has_next, onClick: () => setPage(pageData.num_pages) }, { children: _jsx("span", Object.assign({ "aria-hidden": "true" }, { children: "Utols\u00F3" })) }))] })));
};
export default Pagination;
