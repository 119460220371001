import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useState } from "react";
const AUTH_KEY = "sporoljma_auth";
const convertAuthData = (rawAuthData) => {
    const { access, birthday, email, is_operator, is_staff, refresh, custom_username } = rawAuthData;
    return {
        accessToken: access,
        birthday,
        email: email,
        isOperator: is_operator,
        isStaff: is_staff,
        refreshToken: refresh,
        username: custom_username,
    };
};
const AuthContext = createContext({
    auth: null,
    convertAuthData,
    setAuth: null,
});
const getAuth = () => JSON.parse(window.sessionStorage.getItem(AUTH_KEY) || null);
export const AuthProvider = ({ children }) => {
    const [auth, _setAuth] = useState(getAuth());
    const setAuth = useCallback((data) => {
        window.sessionStorage.setItem(AUTH_KEY, JSON.stringify(data));
        _setAuth(data);
    }, []);
    return (_jsx(AuthContext.Provider, Object.assign({ value: { auth, convertAuthData, setAuth } }, { children: children })));
};
export default AuthContext;
