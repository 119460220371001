import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CCallout, CFormInput, CInputGroup, CInputGroupText } from "@coreui/react";
import { cilLockLocked } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import PasswordRequirements from "./PasswordRequirements";
import PasswordStrengthBar from "react-password-strength-bar";
import { useState } from "react";
import ShowPasswordButton from "../ShowPasswordButton";
const PasswordForm = ({ matchFocus, matchPassword, isNewPassword = false, password, passwordFocus, setMatchFocus, setMatchPassword, setPassword, setPasswordFocus, validMatch, validPassword, }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(CInputGroup, Object.assign({ className: "mb-3" }, { children: [_jsx(CInputGroupText, { children: _jsx(CIcon, { icon: cilLockLocked }) }), _jsx(CFormInput, Object.assign({ autoComplete: "off", name: "password", id: "password", onBlur: () => setPasswordFocus(false), onChange: (e) => setPassword(e.target.value), onFocus: () => setPasswordFocus(true), placeholder: isNewPassword ? "Új jelszó" : "Jelszó", required: true, type: showPassword ? "text" : "password", value: password }, (password ? (validPassword ? { valid: true } : { invalid: true }) : null))), _jsx(ShowPasswordButton, { showPassword: showPassword, setShowPassword: setShowPassword }), " "] })), _jsx(PasswordStrengthBar, { minLength: 8, password: password, scoreWords: ["Nagyon gyenge", "Gyenge", "Közepes", "Erős", "Nagyon erős"], shortScoreWord: "T\u00FAl r\u00F6vid" }), password && passwordFocus && !validPassword && _jsx(PasswordRequirements, {}), _jsxs(CInputGroup, Object.assign({ className: "mb-4" }, { children: [_jsx(CInputGroupText, { children: _jsx(CIcon, { icon: cilLockLocked }) }), _jsx(CFormInput, Object.assign({ autoComplete: "off", onBlur: () => setMatchFocus(false), onChange: (e) => setMatchPassword(e.target.value), onFocus: () => setMatchFocus(true), placeholder: isNewPassword ? "Új jelszó megerősítése" : "Jelszó megerősítése", required: true, type: showPassword2 ? "text" : "password", value: matchPassword }, (matchPassword ? (validMatch ? { valid: true } : { invalid: true }) : null))), _jsx(ShowPasswordButton, { showPassword: showPassword2, setShowPassword: setShowPassword2 }), " "] })), matchPassword && matchFocus && !validMatch && (_jsx(CCallout, Object.assign({ color: "warning" }, { children: "A k\u00E9t jelsz\u00F3nak egyeznie kell" })))] }));
};
export default PasswordForm;
