var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { publicRequest } from "../api/requestManager";
import apiURL from "../constants/apiUrl.constant";
import { DEFAULT_DISTANCE, INITIAL_BASE_DATA, INITIAL_ORIGINAL_DATA, INITIAL_SEARCH_CONDITION, } from "../constants/baseData.constant";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
const INITIAL_FAVOURITES_SETTINGS = {
    favoriteStoreGroups: [],
    newsletterSubscription: false,
};
const INITIAL_BASE_CONTEXT_DATA = {
    baseData: INITIAL_BASE_DATA,
    closeModal: () => { },
    favoritesSettings: INITIAL_FAVOURITES_SETTINGS,
    fetchFavoriteSettings: () => { },
    getInitialBaseData: () => { },
    getFavoriteOffers: () => { },
    getNearestOffers: () => { },
    getOffers: () => { },
    getOnlineShopOffers: () => { },
    getStoreGroups: () => { },
    getTopFiveOffers: () => { },
    handleRequestError401: () => { },
    isLoadingData: false,
    isLoadingFavoritesData: false,
    offersData: null,
    openModal: () => { },
    originalData: INITIAL_ORIGINAL_DATA,
    preventGetOffersRef: { current: false },
    searchCondition: INITIAL_SEARCH_CONDITION,
    setSearchCondition: () => { },
    setSidebarVisible: () => { },
};
const BaseContext = createContext(INITIAL_BASE_CONTEXT_DATA);
export const BaseProvider = ({ children, closeModal, error, openModal, setError, setErrorToastVisible, setGeolocationNotSupportedToastVisible, setSidebarVisible, }) => {
    var _a, _b, _c, _d;
    const axiosPrivate = useAxiosPrivate();
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingFavoritesData, setIsLoadingFavoritesData] = useState(false);
    const [baseData, setBaseData] = useState(INITIAL_BASE_DATA);
    const [offersData, setOffersData] = useState(null);
    const [geolocationPosition, setGeolocationPosition] = useState(null);
    const [originalData, setOriginalData] = useState(INITIAL_ORIGINAL_DATA);
    const [searchCondition, setSearchCondition] = useState(INITIAL_SEARCH_CONDITION);
    const [favoritesSettings, setFavoritesSettings] = useState(INITIAL_FAVOURITES_SETTINGS);
    const preventGetOffersRef = useRef(false);
    const nearestOffersDataRef = useRef({
        currentPage: 1,
        storeDistance: DEFAULT_DISTANCE,
    });
    const handleRequestError401 = useCallback((err, afterError) => {
        const currentStatus = err.response.status;
        if (currentStatus === 401) {
            setAuth(null);
            navigate("/unauthorized");
            return;
        }
        setError(err);
        if (afterError) {
            afterError(err);
        }
    }, 
    // eslint-disable-next-line
    []);
    const getBaseData = useCallback((endpoint) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield publicRequest({ url: endpoint });
        if (response.data && response.status === 200) {
            return response.data;
        }
    }), []);
    const fetchOffers = useCallback((conditions) => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        setIsLoadingData(true);
        const data = {
            city: conditions.city.value === "Összes" ? "all" : conditions.city.value,
            page_number: conditions.currentOfferPage,
            store_group: conditions.storeGroup.id,
            store_type: conditions.storeType.id,
        };
        try {
            const response = yield publicRequest({
                data,
                method: "POST",
                url: apiURL.offerList,
            });
            if (response.data && response.status === 200) {
                setIsLoadingData(false);
                return response.data;
            }
        }
        catch (err) {
            setIsLoadingData(false);
            setError(err);
        }
    }), [setError]);
    const getOffers = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const currentOffersCondition = {
            city: searchCondition.city,
            currentOfferPage: searchCondition.currentOfferPage,
            storeType: searchCondition.storeType,
            storeGroup: searchCondition.storeGroup,
        };
        const offers = yield fetchOffers(currentOffersCondition);
        if (offers) {
            setOffersData(offers);
        }
    }), [
        fetchOffers,
        searchCondition.city,
        searchCondition.currentOfferPage,
        searchCondition.storeType,
        searchCondition.storeGroup,
    ]);
    const getInitialBaseData = useCallback(() => {
        setError(null);
        setIsLoadingData(true);
        const allPromise = Promise.all([
            getBaseData(apiURL.storeTypes),
            getBaseData(apiURL.storeGroups),
            getBaseData(apiURL.cities),
        ]);
        allPromise
            .then((responseData) => {
            setIsLoadingData(false);
            setBaseData({
                storeTypes: [...INITIAL_BASE_DATA.storeTypes, ...responseData[0]],
                storeGroups: [...INITIAL_BASE_DATA.storeGroups, ...responseData[1]],
                cities: [...INITIAL_BASE_DATA.cities, ...responseData[2]],
            });
            setOriginalData({
                originalStoreTypes: responseData[0],
                originalStoreGroups: responseData[1],
            });
        })
            .catch((err) => {
            setIsLoadingData(false);
            setError(err);
        });
    }, [getBaseData, setError]);
    const getStoreGroups = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        setIsLoadingData(true);
        try {
            const newStoreGroups = yield getBaseData(apiURL.storeGroups);
            setBaseData((data) => (Object.assign(Object.assign({}, data), { storeGroups: [...INITIAL_BASE_DATA.storeGroups, ...newStoreGroups] })));
            setOriginalData((data) => (Object.assign(Object.assign({}, data), { originalStoreGroups: newStoreGroups })));
            setIsLoadingData(false);
        }
        catch (err) {
            setError(err);
            setIsLoadingData(false);
        }
    }), [getBaseData, setError]);
    const getFavoriteOffers = useCallback((currentPage) => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        setIsLoadingData(true);
        try {
            const response = yield axiosPrivate.post(apiURL.favoriteOffers, {
                page_number: currentPage,
            }, {
                headers: {
                    Authorization: `Bearer ${auth === null || auth === void 0 ? void 0 : auth.accessToken}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            if (response.data && response.status === 200) {
                const offersData = response.data;
                setOffersData(offersData);
            }
            setIsLoadingData(false);
        }
        catch (err) {
            handleRequestError401(err, () => setIsLoadingData(false));
        }
    }), [auth === null || auth === void 0 ? void 0 : auth.accessToken, axiosPrivate, handleRequestError401, setError]);
    const getTopFiveOffers = useCallback((currentPage) => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        setIsLoadingData(true);
        try {
            const response = yield publicRequest({
                url: apiURL.top5,
                data: {
                    page_number: currentPage,
                },
                method: "POST",
            });
            if (response.data && response.status === 200) {
                const offersData = response.data;
                setOffersData(offersData);
            }
            setIsLoadingData(false);
        }
        catch (err) {
            setError(err);
            setIsLoadingData(false);
        }
    }), [setError]);
    const getOnlineShopOffers = useCallback((currentPage) => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        setIsLoadingData(true);
        try {
            const response = yield publicRequest({
                url: apiURL.onlineOffers,
                data: {
                    page_number: currentPage,
                },
                method: "POST",
            });
            if (response.data && response.status === 200) {
                const offersData = response.data;
                setOffersData(offersData);
            }
            setIsLoadingData(false);
        }
        catch (err) {
            setError(err);
            setIsLoadingData(false);
        }
    }), [setError]);
    const getLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(setGeolocationPosition, () => {
                setGeolocationNotSupportedToastVisible(true);
                setIsLoadingData(false);
            });
        }
        else {
            setGeolocationNotSupportedToastVisible(true);
        }
    }, [setGeolocationNotSupportedToastVisible]);
    const getNearestOffers = useCallback((currentPage, storeDistance) => {
        setError(null);
        setIsLoadingData(true);
        setGeolocationPosition(null);
        nearestOffersDataRef.current = {
            currentPage,
            storeDistance,
        };
        getLocation();
    }, [getLocation, setError]);
    const fetchNearestOffers = useCallback((pageNumber, distance) => __awaiter(void 0, void 0, void 0, function* () {
        var _e, _f, _g, _h;
        if (((_e = geolocationPosition === null || geolocationPosition === void 0 ? void 0 : geolocationPosition.coords) === null || _e === void 0 ? void 0 : _e.latitude) && ((_f = geolocationPosition === null || geolocationPosition === void 0 ? void 0 : geolocationPosition.coords) === null || _f === void 0 ? void 0 : _f.longitude)) {
            try {
                const response = yield publicRequest({
                    data: {
                        user_location: {
                            latitude: (_g = geolocationPosition === null || geolocationPosition === void 0 ? void 0 : geolocationPosition.coords) === null || _g === void 0 ? void 0 : _g.latitude,
                            longitude: (_h = geolocationPosition === null || geolocationPosition === void 0 ? void 0 : geolocationPosition.coords) === null || _h === void 0 ? void 0 : _h.longitude,
                        },
                        page_number: pageNumber,
                        distance,
                    },
                    method: "POST",
                    url: apiURL.nearestOffers,
                });
                if (response.data && response.status === 200) {
                    const offersData = response.data;
                    setOffersData(offersData);
                }
                setIsLoadingData(false);
            }
            catch (err) {
                setIsLoadingData(false);
                setError(err);
            }
        }
        else {
            setIsLoadingData(false);
        }
    }), [(_a = geolocationPosition === null || geolocationPosition === void 0 ? void 0 : geolocationPosition.coords) === null || _a === void 0 ? void 0 : _a.latitude, (_b = geolocationPosition === null || geolocationPosition === void 0 ? void 0 : geolocationPosition.coords) === null || _b === void 0 ? void 0 : _b.longitude, setError]);
    const fetchFavoriteSettings = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        setIsLoadingFavoritesData(true);
        try {
            const response = yield axiosPrivate.get(apiURL.favoritesSettings, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });
            setIsLoadingFavoritesData(false);
            if (response.data && response.status === 200) {
                const { favorite_store_groups, newsletter_subscription } = response.data;
                setFavoritesSettings({
                    favoriteStoreGroups: favorite_store_groups || [],
                    newsletterSubscription: newsletter_subscription,
                });
            }
        }
        catch (err) {
            handleRequestError401(err, () => setIsLoadingFavoritesData(false));
        }
    }), [axiosPrivate, handleRequestError401, setError]);
    useEffect(() => {
        const { currentPage, storeDistance } = nearestOffersDataRef.current;
        fetchNearestOffers(currentPage, storeDistance);
    }, [
        fetchNearestOffers,
        (_c = geolocationPosition === null || geolocationPosition === void 0 ? void 0 : geolocationPosition.coords) === null || _c === void 0 ? void 0 : _c.latitude,
        (_d = geolocationPosition === null || geolocationPosition === void 0 ? void 0 : geolocationPosition.coords) === null || _d === void 0 ? void 0 : _d.longitude,
    ]);
    useEffect(() => {
        if (error) {
            setErrorToastVisible(true);
        }
    }, [error, setErrorToastVisible]);
    useEffect(() => {
        getInitialBaseData();
        // eslint-disable-next-line
    }, []);
    return (_jsx(BaseContext.Provider, Object.assign({ value: {
            baseData,
            closeModal,
            error,
            favoritesSettings,
            fetchFavoriteSettings,
            getFavoriteOffers,
            getInitialBaseData,
            getNearestOffers,
            getOffers,
            getOnlineShopOffers,
            getStoreGroups,
            getTopFiveOffers,
            handleRequestError401,
            isLoadingData,
            isLoadingFavoritesData,
            offersData,
            openModal,
            originalData,
            preventGetOffersRef,
            searchCondition,
            setSearchCondition,
            setSidebarVisible,
        } }, { children: children })));
};
export default BaseContext;
