import { useEffect, useRef, useState } from "react";
import { EMAIL_REGEX } from "../constants/regex.contant";
const useEmailForm = () => {
    const [email, setEmail] = useState("");
    const [emailFocus, setEmailFocus] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const emailRef = useRef();
    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email]);
    useEffect(() => {
        emailRef.current.focus();
        // eslint-disable-next-line
    }, []);
    return {
        email,
        emailFocus,
        emailRef,
        setEmail,
        setEmailFocus,
        validEmail,
    };
};
export default useEmailForm;
