const apiURL = {
    cities: "/api/cities/",
    forgottenPassword: "/api/forgotten-password/",
    login: "/api/token/",
    loginOneTime: "/api/login-one-time/",
    nearestOffers: "/api/nearest-offers/",
    offerList: "/api/offer-list/",
    onlineOffers: "/api/online-offers/",
    refresh: "/api/token/refresh/",
    register: "/api/register/",
    restorePassword: "/api/restore-password/",
    storeGroups: "/api/store-groups/",
    storeTypes: "/api/store-types/",
    top5: "/api/top5/",
    changeEmail: "/api/user/change-email/",
    changePassword: "/api/user/change-password/",
    deleteProfile: "/api/user/delete-user/",
    favoriteOffers: "/api/user/favorite-offers/",
    favoritesSettings: "/api/user/preference/",
    offerManagement: "/api/staff/offers/",
    storeList: "/api/staff/store-list/",
    storeManagement: "/api/staff/stores/",
    storeGroupManagement: "/api/staff/store-groups/",
    storeTypeManagement: "/api/staff/store-types/",
    offersCsv: "/api/staff/offers-csv/",
    storeGroupsCsv: "/api/staff/store-groups-csv/",
    storeTypesCsv: "/api/staff/store-types-csv/",
    storesCsv: "/api/staff/stores-csv/",
    sendNewsletter: "/api/staff/send-newsletter/",
    specialUsers: "/api/staff/special-users/",
    testNewsletter: "/api/staff/test-newsletter/",
    userStatistics: "/api/staff/user-statistics/",
};
export default apiURL;
