import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, } from "@coreui/react";
import { useEffect } from "react";
const WarningModal = ({ closeModal, confirmFunction, size, modalVisible, operation, }) => {
    const handleTyping = (event) => {
        // if (event.key === "Enter") {
        //   const email = document.querySelector("#email").nodeValue;
        //   const password = document.querySelector("#password").nodeValue;
        //   handleSubmit(email, password);
        //   return;
        // }
        if (event.key === "Escape") {
            closeModal();
            return;
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", handleTyping);
        return () => document.removeEventListener("keydown", handleTyping);
        // eslint-disable-next-line
    }, []);
    return (_jsxs(CModal, Object.assign({ backdrop: "static", visible: modalVisible, size: size, onClose: closeModal }, { children: [_jsx(CModalHeader, Object.assign({ className: "bg-warning" }, { children: _jsx(CModalTitle, { children: "Biztos?" }) })), _jsxs(CModalBody, { children: [_jsx("p", { children: "Biztos, hogy az al\u00E1bbi m\u0171veletet v\u00E9grehajtod?" }), _jsx("h5", Object.assign({ className: "text-danger" }, { children: operation }))] }), _jsxs(CModalFooter, { children: [_jsx(CButton, Object.assign({ color: "secondary", onClick: () => closeModal() }, { children: "M\u00E9gsem" })), _jsx(CButton, Object.assign({ color: "warning", onClick: confirmFunction }, { children: "Igen" }))] })] })));
};
export default WarningModal;
