var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from "react";
import { axiosPrivate } from "../api/requestManager";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth, logout } = useAuth();
    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use((config) => {
            if (!config.headers["Authorization"]) {
                config.headers["Authorization"] = `Bearer ${auth === null || auth === void 0 ? void 0 : auth.accessToken}`;
            }
            return config;
        }, (error) => Promise.reject(error));
        const responseIntercept = axiosPrivate.interceptors.response.use((response) => response, (error) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const prevRequest = error === null || error === void 0 ? void 0 : error.config;
            if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && !(prevRequest === null || prevRequest === void 0 ? void 0 : prevRequest.sent)) {
                prevRequest.sent = true;
                const newAccessToken = yield refresh();
                if (!newAccessToken) {
                    return;
                }
                prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
                return axiosPrivate(prevRequest);
            }
            return Promise.reject(error);
        }));
        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, [auth, logout, refresh]);
    return axiosPrivate;
};
export default useAxiosPrivate;
