var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import BaseContext from "../contexts/BaseProvider";
import apiURL from "../constants/apiUrl.constant";
import useAuth from "./useAuth";
import { publicRequest } from "../api/requestManager";
const useRefreshToken = () => {
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const { handleRequestError401 } = useContext(BaseContext);
    const refresh = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const response = yield publicRequest({
                method: "POST",
                url: apiURL.refresh,
                data: { refresh: auth.refreshToken },
            });
            if ((response === null || response === void 0 ? void 0 : response.status) === 401) {
                setAuth(null);
                navigate("/unauthorized");
            }
            else {
                setAuth(Object.assign(Object.assign({}, auth), { accessToken: response.data.access }));
            }
            return (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.access;
        }
        catch (error) {
            handleRequestError401(error);
            return null;
        }
    });
    return refresh;
};
export default useRefreshToken;
