var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CFormCheck } from "@coreui/react";
import { useEffect, useState } from "react";
const StatefulCheckBox = (_a) => {
    var { className = "", defaultChecked, id, key, label } = _a, rest = __rest(_a, ["className", "defaultChecked", "id", "key", "label"]);
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(defaultChecked);
    }, [defaultChecked]);
    return (_jsx(CFormCheck, Object.assign({ className: className, id: id, label: label, checked: checked, onChange: () => setChecked(!checked) }, (rest && rest)), key));
};
export default StatefulCheckBox;
