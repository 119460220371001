import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { ModalEnum } from "../interfaces/modal.interface";
const RequireAuth = ({ operatorOnly = false, staffOnly = false, }) => {
    const { auth } = useAuth();
    const { openModal } = useOutletContext();
    if (!(auth === null || auth === void 0 ? void 0 : auth.username)) {
        openModal(ModalEnum.Login);
        return _jsx(Navigate, { to: "/", replace: true });
    }
    if (operatorOnly && !(auth === null || auth === void 0 ? void 0 : auth.isStaff) && !(auth === null || auth === void 0 ? void 0 : auth.isOperator)) {
        return _jsx(Navigate, { to: "/unauthorized", replace: true });
    }
    if (staffOnly && !(auth === null || auth === void 0 ? void 0 : auth.isStaff)) {
        return _jsx(Navigate, { to: "/unauthorized", replace: true });
    }
    return _jsx(Outlet, {});
};
export default RequireAuth;
