import { StoreGroupSlug } from "../interfaces/baseData.interface";
const INITIAL_ORIGINAL_DATA = {
    originalStoreTypes: [],
    originalStoreGroups: [],
};
const ALL_TYPE_OPTION = { name: "Összes", name_plural: "-", id: "all", slug: "all" };
const ALL_GROUP_OPTION = {
    id: "all",
    image: "none",
    logo: "none",
    name: "Összes",
    online_store: false,
    slug: StoreGroupSlug.All,
    store_type_id: 0,
};
const DEFAULT_DISTANCE = 5;
const INITIAL_BASE_DATA = {
    cities: ["Összes"],
    storeTypes: [ALL_TYPE_OPTION],
    storeGroups: [ALL_GROUP_OPTION],
};
const INITIAL_SEARCH_CONDITION = {
    city: { value: String(INITIAL_BASE_DATA.cities[0]), label: String(INITIAL_BASE_DATA.cities[0]) },
    currentOfferPage: 1,
    storeGroup: INITIAL_BASE_DATA.storeGroups[0],
    storeType: INITIAL_BASE_DATA.storeTypes[0],
};
const INITIAL_OFFER_CONDITION = {
    city: "all",
    page_number: 1,
    store_group: "all",
    store_type: "all",
};
export { ALL_GROUP_OPTION, ALL_TYPE_OPTION, DEFAULT_DISTANCE, INITIAL_BASE_DATA, INITIAL_OFFER_CONDITION, INITIAL_ORIGINAL_DATA, INITIAL_SEARCH_CONDITION, };
