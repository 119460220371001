import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { CContainer } from "@coreui/react";
import { Footer } from "../index";
import "./TextPageContent.scss";
const TextPageContent = ({ children, fullWidth = false, imageSrc, title, }) => {
    const [showBackground, setShowBackground] = useState(true);
    const isBackgroundPreviouslyShown = useRef(true);
    useEffect(() => {
        const measureWindowForBackground = () => {
            const isWindowLarge = window.innerWidth >= 992;
            if (isWindowLarge !== isBackgroundPreviouslyShown.current) {
                isBackgroundPreviouslyShown.current = isWindowLarge;
                setShowBackground(isWindowLarge);
            }
        };
        window.addEventListener("resize", measureWindowForBackground);
        measureWindowForBackground();
        return () => window.removeEventListener("resize", measureWindowForBackground);
    }, []);
    return (_jsxs("div", Object.assign({ className: "flex-fill d-flex flex-column bg-main overflow-auto text-page" }, { children: [_jsxs("main", Object.assign({ className: "flex-fill d-flex flex-row text-page__main" }, { children: [imageSrc && showBackground && (_jsx("aside", Object.assign({ className: "image-container" }, { children: _jsx("img", { src: imageSrc, alt: `${title} oldal háttérkép`, width: "100%", height: "100%" }) }))), _jsx("div", Object.assign({ className: "overflow-auto w-100" }, { children: _jsxs(CContainer, Object.assign({}, (fullWidth ? { fluid: true } : { sm: true }), { className: "h-100 flex-fill py-5 px-5 text-primary text-page-container" }, { children: [_jsx("h1", Object.assign({ className: "text-danger text-uppercase mb-5 text-title" }, { children: title })), children] })) }))] })), _jsx(Footer, {})] })));
};
export default TextPageContent;
