import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CIcon from "@coreui/icons-react";
import { CInputGroup, CInputGroupText, CPagination, CPaginationItem } from "@coreui/react";
import { cilCalendar } from "@coreui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.scss";
const months = [
    "Január",
    "Február",
    "Március",
    "Április",
    "Május",
    "Június",
    "Július",
    "Augusztus",
    "Szeptember",
    "Október",
    "November",
    "December",
];
const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step); //range(1990, new Date().getFullYear() + 1, 1);
const years = range(1900, new Date().getFullYear(), 1);
const CustomDatePicker = ({ date, setDate }) => {
    return (_jsxs(CInputGroup, Object.assign({ className: "mb-3" }, { children: [_jsx(CInputGroupText, { children: _jsx(CIcon, { icon: cilCalendar }) }), _jsx(DatePicker, { className: "form-control date-input", dateFormat: "yyyy.MM.dd.", onChange: setDate, selected: date, renderCustomHeader: ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => (_jsxs(CPagination, Object.assign({ className: "d-flex justify-content-center m-2" }, { children: [_jsx(CPaginationItem, Object.assign({ "aria-label": "El\u0151z\u0151", className: "cursor-pointer", disabled: prevMonthButtonDisabled, onClick: decreaseMonth }, { children: "<" })), _jsx("select", Object.assign({ className: "form-control rounded-0", value: date.getFullYear(), onChange: ({ target: { value } }) => changeYear(Number(value)) }, { children: years.map((option) => (_jsx("option", Object.assign({ value: option }, { children: option }), option))) })), _jsx("select", Object.assign({ className: "form-control rounded-0", value: months[date.getMonth()], onChange: ({ target: { value } }) => changeMonth(months.indexOf(value)) }, { children: months.map((option) => (_jsx("option", Object.assign({ value: option }, { children: option }), option))) })), _jsx(CPaginationItem, Object.assign({ "aria-label": "K\u00F6vetkez\u0151", className: "cursor-pointer", disabled: nextMonthButtonDisabled, onClick: increaseMonth }, { children: ">" }))] }))) })] })));
};
export default CustomDatePicker;
